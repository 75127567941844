import React, { useState, useEffect, createRef } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import updateChitChatMessage from "../utils/update-chitchat-message"
import deleteChitChatMessage from "../utils/delete-chitchat-message"

import trashCanIcon from "../images/trash-can-icon.svg"
import sendIcon from "../images/send-icon.svg"
import closeIcon from "../images/close-black.svg"

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 0.75rem;
`

const Button = styled.button`
  padding: 0.5625rem 1rem;
  border: 1px solid #363636;
  border-radius: 0.3125rem;
  background: none;
  color: #363636;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 600;
`

const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.3125rem;
  height: 2.3125rem;
  margin-left: 0.75rem;
  padding: 0;
  border: 0;
  border-radius: 50%;
  background: ${props => props.theme.headingColor};

  img {
    display: block;
  }
`

const ReplyWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 0.75rem;

  @media (min-width: 600px) {
    align-items: center;
  }
`

const CloseButton = styled.button`
  padding: 0;
  border: 0;
  background: none;

  img {
    display: block;
  }
`

const Form = styled.form`
  width: 100%;

  @media (max-width: 599px) {
    button {
      margin-left: 0;
      margin-top: 0.75rem;
    }
  }

  @media (min-width: 600px) {
    display: flex;
  }
`

const Input = styled.input`
  display: block;
  width: 100%;
  flex-grow: 1;
  font-size: 1rem;
  padding: 0.5rem;
  border: 1px solid #363636;
  border-radius: 0.3125rem;

  @media (min-width: 600px) {
    border-radius: 0.3125rem 0 0 0.3125rem;
  }
`

const SendButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 0.625rem;
  border: 1px solid #363636;
  border-radius: 0.3125rem;
  background: #363636;
  color: white;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 600;
  white-space: nowrap;

  img {
    display: block;
    margin-right: 0.25rem;
  }

  @media (min-width: 600px) {
    border-radius: 0 0.3125rem 0.3125rem 0;
  }
`

export default function ChitChatCmsControls({ password, message, isReply, onDelete, onUpdate }) {
  const intl = useIntl()
  const inputRef = createRef()
  const [replyMode, setReplyMode] = useState(false)
  const [replyMessage, setReplyMessage] = useState("")
  const [isReplying, setIsReplying] = useState(false)

  const handleReply = (e) => {
    e.preventDefault()
    setIsReplying(true)

    updateChitChatMessage(message.id, replyMessage, password)
      .then((res) => {
        setReplyMode(false)
        setReplyMessage("")
        setIsReplying(false)
        onUpdate(message.id, res.data)
      })
      .catch((e) => {
        console.log(e)
        alert("Något gick fel")
        setIsReplying(false)
      })
  }

  const handleRemove = () => {
    if (isReply) {
      if (confirm("Är du säker på att du vill ta bort detta svar?")) {
        updateChitChatMessage(message.id, "", password)
          .then((res) => {
            onUpdate(message.id, res.data)
          })
          .catch((e) => {
            console.log(e)
            alert("Något gick fel")
          })
      }
    } else {
      if (confirm("Är du säker på att du vill ta bort detta meddelande?")) {
        deleteChitChatMessage(message.id, password)
          .then(() => {
            onDelete(message.id)
          })
          .catch((e) => {
            console.log(e)
            alert("Något gick fel")
          })
      }
    }
  }

  const handleEdit = () => {
    setReplyMessage(message.reply)
    setReplyMode(true)
  }

  useEffect(() => {
    if (replyMode) {
      inputRef.current.focus()
    }
  }, [replyMode])

  return (
    <>
      {!replyMode ?
        <Wrapper>
          {isReply ?
            <Button onClick={handleEdit} disabled={!password}>Redigera</Button>
          :
            <>
              {!message.reply &&
                <Button onClick={() => setReplyMode(true)} disabled={!password}>Svara</Button>
              }
            </>
          }
          <DeleteButton onClick={handleRemove} disabled={!password}>
            <img src={trashCanIcon} alt="Ta bort" />
          </DeleteButton>
        </Wrapper>
      :
        <ReplyWrapper>
          <CloseButton onClick={() => setReplyMode(false)}>
            <img src={closeIcon} alt="Stäng" />
          </CloseButton>
          <Form onSubmit={handleReply}>
            <Input
              ref={inputRef}
              placeholder="Skriv svar här..."
              value={replyMessage}
              onChange={(e) => setReplyMessage(e.target.value)}
            />
            <SendButton type="submit" disabled={isReplying}>
              <img src={sendIcon} alt="" /> {isReplying ? "Skickar..." : "Skicka"}
            </SendButton>
          </Form>
        </ReplyWrapper>
      }
    </>
  )
}
