import isProd from "./is-prod"

if (typeof window !== "undefined") {
  require("whatwg-fetch")
}

export default function updateChitChatMessage(id, reply, password) {
  const url = `/.netlify/functions/update_chitchat_message`

  return isProd()
    ? window.fetch(url, {
      body: `id=${id}&reply=${reply}&pwd=${password}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      method: "put",
    })
    .then(res => res.json())
    : Promise.resolve({ data: { reply, reply_timestamp: Date.now() } })
}
