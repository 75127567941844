import React, { useState, useEffect } from "react"
import styled from "styled-components"
import TimeAgo from "javascript-time-ago"
import sv from "javascript-time-ago/locale/sv"

import LogoLink from "../components/LogoLink"
import NwHelmet from "../components/NwHelmet"
import ChitChatBubble from "../components/ChitChatBubble"
import ChitChatCmsControls from "../components/ChitChatCmsControls"
import ChitChatPasswordPrompt from "../components/ChitChatPasswordPrompt"

import getChitChatMessages from "../utils/get-chitchat-messages"

TimeAgo.addLocale(sv)

const Wrapper = styled.div`
  padding: 1rem 0;

  h1 {
    text-align: center;
  }
`

const StyledLogoLink = styled(LogoLink)`
  width: 10rem;
  height: auto;
  margin-left: 1rem;
`

const ErrorMessage = styled.p`
  text-align: center;
`

const LangSelection = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`

const LangButton = styled.button`
  padding: 0.5rem 1rem;
  border: 0;
  border-bottom: 3px solid transparent;
  background: none;
  text-transform: uppercase;
  font-size: 1.125rem;

  ${props => props.isSelected && `
    border-color: ${props.theme.headingColor};
  `}
`

const Inner = styled.div`
  max-width: 37.5rem;
  margin: 0 auto;
`

const MessagesWrapper = styled.div`
  padding: 3.4375rem 1.625rem 1rem;
`

const Messages = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const LoadMoreButtonWrapper = styled.div`
  margin-top: 1.25rem;
  text-align: center;
`

const LoadMoreButton = styled.button`
  padding: 0.5rem 0.625rem;
  border: 1px solid ${props => props.theme.textColor};
  border-radius: 9999px;
  color: ${props => props.theme.textColor};
  background: none;
`

export default function ChitChatCms() {
  const [password, setPassword] = useState("")
  const [showPasswordPrompt, setShowPasswordPrompt] = useState(false)
  const [selectedLang, setSelectedLang] = useState("sv")
  const [svMessages, setSvMessages] = useState([])
  const [enMessages, setEnMessages] = useState([])
  const [svPagination, setSvPagination] = useState("")
  const [enPagination, setEnPagination] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  const getMessages = (lang) => {
    setIsLoading(true)
    setHasError(false)

    const svPromise = !lang || lang === "sv" ? getChitChatMessages({ lang: "sv", after: svPagination }) : Promise.resolve()
    const enPromise = !lang || lang === "en" ? getChitChatMessages({ lang: "en", after: enPagination }) : Promise.resolve()

    Promise.all([svPromise, enPromise])
      .then(([svRes, enRes]) => {
        if (svRes) {
          setSvMessages(svMessages.concat(svRes.data))
          setSvPagination(svRes.after ? `${svRes.after[0]},${svRes.after[1]["@ref"].id}`  : "")
        }
        if (enRes) {
          setEnMessages(enMessages.concat(enRes.data))
          setEnPagination(enRes.after ? `${enRes.after[0]},${enRes.after[1]["@ref"].id}`  : "")
        }
        setIsLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setHasError(true)
        setIsLoading(false)
      })
  }

  const getCmsControls = (message, isReply = false) => (
    <ChitChatCmsControls
      password={password}
      message={message}
      isReply={isReply}
      onDelete={handleDeleteMessage}
      onUpdate={handleUpdateMessage}
    />
  )

  const handleDeleteMessage = (id) => {
    const messageFilter = (message) => message.id !== id

    if (selectedLang === "sv") {
      setSvMessages(svMessages.filter(messageFilter))
    } else {
      setEnMessages(enMessages.filter(messageFilter))
    }
  }

  const handleUpdateMessage = (id, data) => {
    const updateMessage = (message) => message.id === id ? ({
      ...message,
      ...data,
    }) : message

    if (selectedLang === "sv") {
      setSvMessages(svMessages.map(updateMessage))
    } else {
      setEnMessages(enMessages.map(updateMessage))
    }
  }

  const handleSetPassword = (pwd, save) => {
    setPassword(pwd)
    setShowPasswordPrompt(false)

    if (save) {
      window.localStorage.setItem("chitchat_pwd", window.btoa(pwd))
    }
  }

  useEffect(() => {
    getMessages()

    const pwd = window.localStorage.getItem("chitchat_pwd")

    if (pwd) {
      setPassword(window.atob(pwd))
    } else {
      setShowPasswordPrompt(true)
    }
  }, [])

  return (
    <Wrapper>
      <NwHelmet lang="sv" title="ChitChat CMS" noIndex />
      <StyledLogoLink to="/" lang="sv" />
      <h1>ChitChat CMS</h1>
      {showPasswordPrompt &&
        <Inner>
          <ChitChatPasswordPrompt onUpdate={handleSetPassword} />
        </Inner>
      }
      <Inner>
        <LangSelection>
          <LangButton
            onClick={() => setSelectedLang("sv")}
            isSelected={selectedLang === "sv"}
          >
            Svenska
          </LangButton>
          <LangButton
            onClick={() => setSelectedLang("en")}
            isSelected={selectedLang === "en"}
          >
            Engelska
          </LangButton>
        </LangSelection>
      </Inner>
      {hasError &&
        <ErrorMessage>Lyckades inte ladda in några meddelanden</ErrorMessage>
      }
      <MessagesWrapper>
        <Inner>
          {isLoading && svMessages.length === 0 && enMessages.length === 0 &&
            <>
              <span className="structural">Laddar</span>
              <ChitChatBubble placeholder />
              <ChitChatBubble placeholder />
              <ChitChatBubble placeholder />
            </>
          }
          {svMessages.length > 0 && selectedLang === "sv" &&
            <Messages>
              {svMessages.map((message) => (
                <li key={`${message.id}`}>
                  <ChitChatBubble lang="sv" message={message} getCmsControls={getCmsControls} />
                </li>
              ))}
            </Messages>
          }
          {enMessages.length > 0 && selectedLang === "en" &&
            <Inner>
              <Messages>
                {enMessages.map((message) => (
                  <li key={`${message.id}`}>
                    <ChitChatBubble lang="sv" message={message} getCmsControls={getCmsControls} />
                  </li>
                ))}
              </Messages>
            </Inner>
          }
        </Inner>
        {((selectedLang === "sv" && !!svPagination) || (selectedLang === "en" && !!enPagination)) &&
          <LoadMoreButtonWrapper>
            <LoadMoreButton onClick={() => getMessages(selectedLang)}>
              {isLoading ? "Laddar" : "Ladda"} fler{isLoading ? "..." : ""}
            </LoadMoreButton>
          </LoadMoreButtonWrapper>
        }
      </MessagesWrapper>
    </Wrapper>
  )
}
