import React, { useState } from "react"
import styled from "styled-components"

const Form = styled.form`
  margin-top: 2rem;
  padding: 1rem;
  background: #eee;
`

const Flex = styled.div`
  display: flex;
  margin: 0.25rem 0;
`

const Input = styled.input`
  display: block;
  width: 100%;
  font-size: 1rem;
  padding: 0.5rem;
  border: 1px solid #363636;
  border-radius: 0.3125rem 0 0 0.3125rem;
`

const Button = styled.button`
  padding: 0.5rem 1rem;
  border: 1px solid #363636;
  border-radius: 0 0.3125rem 0.3125rem 0;
  background: #363636;
  color: white;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 600;
`

export default function ChitChatPasswordPrompt({ onUpdate }) {
  const [password, setPassword] = useState("")
  const [save, setSave] = useState(false)

  return (
    <Form onSubmit={() => onUpdate(password, save)}>
      <label htmlFor="password">Skriv in ditt lösenord för att svara på eller ta bort meddelanden</label>
      <Flex>
        <Input id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        <Button type="submit">OK</Button>
      </Flex>
      <label>
        <input type="checkbox" checked={save} onChange={(e) => setSave(e.target.checked)} />
        Spara lösenord (kryssa inte i om du sitter på en offentlig dator)
      </label>
    </Form>
  )
}
