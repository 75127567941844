import isProd from "./is-prod"

if (typeof window !== "undefined") {
  require("whatwg-fetch")
}

export default function deleteChitChatMessage(id, password) {
  const url = `/.netlify/functions/delete_chitchat_message?id=${id}&pwd=${password}`

  return isProd()
    ? window.fetch(url, {
      method: "delete",
    })
    .then(res => res.json())
    : Promise.resolve()
}
